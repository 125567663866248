import { signUpSchema } from '@propertypal/shared/src/constants/user';
import { useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { setLoggedIn, webRegisterWithPropertyPal } from '@propertypal/shared/src/reducers/user';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import platform from '@propertypal/shared/src/utils/platform';
import Button from '@propertypal/web-ui/src/buttons/Button';
import ErrorMessage from '@propertypal/web-ui/src/errors/ErrorMessage';
import Checkbox from '@propertypal/web-ui/src/inputs/Checkbox';
import TextInput from '@propertypal/web-ui/src/inputs/TextInput';
import Recaptcha, { getCaptchaKey } from '@propertypal/web-ui/src/security/Recaptcha';
import { LinkText, Text } from '@propertypal/web-ui/src/typography';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneNumberInput from '../../../../web-ui/src/inputs/PhoneNumberInput';
import { LoginFormContainer } from './Login.style';

interface RegisterFormProps {
  onRevealFields: () => void;
  revealFields: boolean;
  redirectLocation: string;
}

const GENERIC_ERROR = `Oops, something has went wrong during registration, contact ${platform.helpEmail}`;

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  prefix: '+44',
  phoneNumber: '',
  pass: '',
  passwordConfirm: '',
  terms: false,
};

const checkboxLabel = (
  <Text fontSize={14}>
    I have read and agreed to the{' '}
    <LinkText href="/terms" target="_blank" rel="noreferrer">
      Terms &amp; Conditions
    </LinkText>
  </Text>
);

const INPUT_STYLE = {
  marginBottom: 15,
};

const RegisterForm = (props: RegisterFormProps) => {
  const asyncDispatcher = useAppDispatch();
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const onFormSubmit = async (values: any, { setErrors }: any) => {
    setLoading(true);

    try {
      const recaptchaKey = await getCaptchaKey('createUser');

      await asyncDispatcher(
        webRegisterWithPropertyPal(
          values.firstName,
          values.lastName,
          values.email.trim(),
          `${values.prefix}${values.phoneNumber}`,
          values.pass,
          recaptchaKey,
        ),
      );

      await fetch('/api/auth/providers/propertypal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
          password: values.pass,
        }),
      });

      const url = `${props.redirectLocation}${window.location.hash}`;

      trackGaEvent('sign_up', {
        method: 'email',
        form_location: 'login',
      });

      dispatch(setLoggedIn(true));
      await router.push(url);
    } catch (err: any) {
      setLoading(false);

      if (err?.data?.errors) {
        setErrors(err.data.errors);
      } else {
        setError(GENERIC_ERROR);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <LoginFormContainer visible>
      <Recaptcha />

      <form onSubmit={formik.handleSubmit}>
        <ErrorMessage testID="errorMessage" show={!!error} errorHeader="Error" errorMessage={error} />

        <TextInput
          name="email"
          placeholder="Email address"
          value={formik.values.email}
          onValueChange={formik.handleChange('email')}
          error={formik.touched.email && formik.errors.email}
          type="email"
          containerStyle={INPUT_STYLE}
          onFocus={props.onRevealFields}
        />

        <div style={{ display: props.revealFields ? 'block' : 'none' }}>
          <TextInput
            name="firstName"
            placeholder="First Name"
            value={formik.values.firstName}
            onValueChange={formik.handleChange('firstName')}
            error={formik.touched.firstName && formik.errors.firstName}
            containerStyle={INPUT_STYLE}
          />

          <TextInput
            name="lastName"
            placeholder="Surname"
            value={formik.values.lastName}
            onValueChange={formik.handleChange('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
            containerStyle={INPUT_STYLE}
          />

          <PhoneNumberInput
            name="phoneNumber"
            placeholder="Phone Number"
            value={formik.values.phoneNumber}
            onValueChange={formik.handleChange('phoneNumber')}
            error={(formik.touched.phoneNumber && formik.errors.phoneNumber) || formik.errors.prefix}
            prefix={formik.values.prefix}
            onPrefixChange={formik.handleChange('prefix')}
            containerStyle={INPUT_STYLE}
          />

          <TextInput
            name="password"
            placeholder="Password"
            type="password"
            value={formik.values.pass}
            onValueChange={formik.handleChange('pass')}
            error={formik.touched.pass && formik.errors.pass}
            containerStyle={INPUT_STYLE}
          />

          <TextInput
            name="passwordConfirm"
            placeholder="Confirm Password"
            type="password"
            value={formik.values.passwordConfirm}
            onValueChange={formik.handleChange('passwordConfirm')}
            error={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
            containerStyle={INPUT_STYLE}
          />

          <Checkbox
            name="terms"
            onValueChange={(checked) => formik.setFieldValue('terms', checked)}
            value={formik.values.terms}
            label={checkboxLabel}
            containerStyle={INPUT_STYLE}
            testID="terms-checkbox"
            error={formik.touched.terms && formik.errors.terms}
          />

          <Button loading={loading} testID="registerSubmit" type="submit">
            Sign up with your email
          </Button>
        </div>
      </form>
    </LoginFormContainer>
  );
};

export default RegisterForm;
