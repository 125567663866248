import Button from '@propertypal/web-ui/src/buttons/Button';
import Card from '@propertypal/web-ui/src/card/Card';
import ErrorMessage from '@propertypal/web-ui/src/errors/ErrorMessage';
import Chevron from '@propertypal/web-ui/src/icons/Chevron';
import Tabs from '@propertypal/web-ui/src/tabs/Tabs';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { LoginComponentProps } from '../../types/LoginComponentProps';
import { LoginFormStyle, LoginItem, LoginCardContainer, EstateAgentLoginRow } from './Login.style';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import SocialLogins from './SocialLogins';

const TABS = [
  { key: 'login', text: 'Login' },
  { key: 'signup', text: 'Sign Up' },
];

const LoginFormContainer = (props: LoginComponentProps) => {
  const [revealFields, setRevealFields] = useState(false);
  const [tab, setTab] = useState(props.initialTab || 'login');
  const router = useRouter();

  return (
    <LoginItem>
      <LoginCardContainer>
        <Card>
          <Tabs tabs={TABS} activeTab={tab} onTabClick={setTab} disableArrows />

          {!!router.query.error && (
            <ErrorMessage
              errorHeader="We couldn't log you in"
              errorMessage="Please try another method or check again later"
              show
            />
          )}

          {tab === 'login' && (
            <LoginFormStyle>
              <SocialLogins prefix="Login" hide={false} redirectLocation={props.redirectLocation} />

              <LoginForm {...props} />
            </LoginFormStyle>
          )}

          {tab === 'signup' && (
            <LoginFormStyle>
              <SocialLogins prefix="Sign up" hide={revealFields} redirectLocation={props.redirectLocation} />

              <RegisterForm
                redirectLocation={props.redirectLocation}
                revealFields={revealFields}
                onRevealFields={() => setRevealFields(true)}
              />
            </LoginFormStyle>
          )}
        </Card>

        <EstateAgentLoginRow>
          <p>
            <a href="https://client.propertypal.com" target="_blank" rel="noreferrer">
              Estate Agents log in here <Chevron size={14} />
            </a>
          </p>

          <a href="https://client.propertypal.com" target="_blank" rel="noreferrer">
            <Button>Estate Agents Login</Button>
          </a>
        </EstateAgentLoginRow>
      </LoginCardContainer>
    </LoginItem>
  );
};

export default LoginFormContainer;
