import { laptopMax } from '@propertypal/shared/src/constants/mediaQueries';
import { Container, Content, Tab } from '@propertypal/web-ui/src/tabs/Tabs.style';
import styled from 'styled-components';

const PP_MAX_WIDTH = '1000px';
const PP_MIN_WIDTH = '1001px';

const SIZE = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  mobileXL: 600,
  tablet: 768,
  largeTablet: 960,
  laptop: 1024,
  laptopL: 1440,
  desktop: 256,
};

const DEVICE = {
  mobMax: `(max-width: ${SIZE.mobileXL}px)`,
  tabletMin: `(min-width: ${SIZE.tablet + 1}px)`,
  tabletMax: `(max-width: ${SIZE.tablet}px)`,
  desktopMax: `(max-width: ${SIZE.desktop}px)`,
};

const CONTAINER_MARGIN = '40px';
const CONTAINER_MARGIN_MOB = '20px';
const CONTAINER_WIDTH_FIRST_ITEM = 57.5;
const CONTAINER_WIDTH_SECOND_ITEM = 100 - CONTAINER_WIDTH_FIRST_ITEM;
const { tabletMin, tabletMax, mobMax } = DEVICE;

export interface LoginContainerProps {
  loginBg?: string;
  noBg?: boolean;
  bgColour?: string;
  contentHeader?: number;
  initialTab?: string;
}

export const LoginContainer = styled.div<LoginContainerProps>`
  ${({ noBg, loginBg }) => (!noBg ? `background: url('${loginBg}') no-repeat center center fixed;` : ``)}
  ${({ bgColour }) => (bgColour ? `background-color: ${bgColour};` : ``)}
  background-size: cover;
  min-height: ${({ contentHeader }) => (contentHeader ? `calc(100vh - ${contentHeader}px)` : `100vh`)};
  left: 0;
  height: 100%;
  width: 100%;
  display: table; // Support for IE
  display: grid;
`;

export const LoginWrapper = styled.main`
  max-width: calc(1000px - 15px * 2);
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  display: table;
`;

export const LoginSection = styled.div`
  display: flex;
  padding: 0 15px;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const LoginBenefitsContainer = styled.aside`
  width: calc(42.5% - 40px);
  margin: 0 20px;
  display: inline-block;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
`;

export const LoginItem = styled.section`
  vertical-align: top;
  display: inline-block;
  padding: 50px 0;

  @media screen and (min-width: ${PP_MIN_WIDTH}) {
    margin: 0 ${CONTAINER_MARGIN};
    &:nth-of-type(1) {
      margin-left: 0;
      width: calc(${CONTAINER_WIDTH_FIRST_ITEM}% - ${CONTAINER_MARGIN});
    }
    &:nth-of-type(2) {
      margin-right: 0;
      width: calc(${CONTAINER_WIDTH_SECOND_ITEM}% - ${CONTAINER_MARGIN}});
    }
  }

  @media screen and (max-width: ${PP_MAX_WIDTH}) and ${tabletMin} {
    margin: 0 ${CONTAINER_MARGIN_MOB};
    &:nth-of-type(1) {
      margin-left: 0;
      width: calc(${CONTAINER_WIDTH_FIRST_ITEM}%);
    }
    &:nth-of-type(2) {
      margin-right: 0;
      width: calc(${CONTAINER_WIDTH_SECOND_ITEM}% - ${CONTAINER_MARGIN_MOB}});
    }
  }

  @media screen and ${tabletMax} {
    width: 100%;
  }

  @media screen and ${mobMax} {
    padding: 30px 0;
  }
`;

export const LoginBenefitsTitle = styled.h2`
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.1;

  @media screen and ${tabletMax} {
    text-align: center;
    font-size: 1.25em;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.1em;
  }
`;

export const LoginFormStyle = styled.div<{ agentsLogin?: boolean }>`
  background: ${(props) => props.theme.white};
  padding: ${(props) => (props.agentsLogin ? '40px 42px 50px 42px' : '40px 60px 60px 60px')};
  @media screen and (max-width: ${PP_MAX_WIDTH}) {
    padding: 30px;
  }
  @media screen and ${mobMax} {
    padding: 30px 10px;
  }
`;
export const LoginFormContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const SignupOptionsContainer = styled.div<{ hide: boolean }>`
  max-height: 470px;
  opacity: 1;
  transition:
    max-height 600ms 0ms,
    max-width 600ms 0ms,
    opacity 600ms 0ms;
  ${(props) =>
    props.hide
      ? `
      max-width:0;
      max-height:0;
      opacity: 0;
      overflow: hidden;
      position:relative;
   `
      : null}
`;

export const LoginCardContainer = styled.div`
  max-width: 500px;
  margin: auto;

  ${Container} {
    background-color: ${({ theme }) => theme.background};
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundLighter};
  }

  ${Content} {
    padding: 0;
  }

  ${Tab} {
    flex: 1;
    font-size: 18px;

    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.backgroundLighter};
    }
  }
`;

export const LoginBenefitsWrap = styled.div`
  @media screen and ${tabletMax} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ${mobMax} {
    display: block;
  }
`;

export const EstateAgentLoginRow = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 30px 60px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 15px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);

  & > p {
    flex: 1;
    color: ${({ theme }) => theme.textDark};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  & > a {
    width: 100%;
    max-width: 200px;
  }

  @media (${laptopMax}) {
    flex-direction: column;
    padding: 30px 15px;

    & > a {
      max-width: 280px;
    }
  }
`;
