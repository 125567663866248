import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import platform from '@propertypal/shared/src/utils/platform';
import Button from '@propertypal/web-ui/src/buttons/Button';
import HorizontalDivider from '@propertypal/web-ui/src/divider/HorizontalDivider';
import { Heading } from '@propertypal/web-ui/src/typography';
import React from 'react';
import { useTheme } from 'styled-components';
import { SignupOptionsContainer } from './Login.style';

interface Props {
  prefix: string;
  hide: boolean;
  redirectLocation: string;
}

const SocialLogins = (props: Props) => {
  const theme = useTheme();

  const onClick = (provider: string) => async () => {
    const eventName = props.prefix === 'Login' ? 'login' : 'sign_up';

    trackGaEvent(eventName, {
      method: provider,
      form_location: 'login',
    });

    window.location.assign(`/api/auth/providers/${provider}?callbackUrl=${props.redirectLocation}`);
  };

  return (
    <SignupOptionsContainer hide={props.hide}>
      <Heading align="center" mb={20}>
        {props.prefix} to {platform.title}
      </Heading>

      <Button
        containerStyle={{ marginBottom: 20 }}
        icon={faFacebookF}
        color={theme.facebookBlue}
        testID="facebookButton"
        onClick={onClick('facebook')}
      >
        {props.prefix} with Facebook
      </Button>

      <Button
        containerStyle={{ marginBottom: 20 }}
        icon={faGoogle}
        color={theme.googleRed}
        testID="googleButton"
        onClick={onClick('google')}
      >
        {props.prefix} with Google
      </Button>

      <Button
        containerStyle={{ marginBottom: 20 }}
        icon={faApple}
        testID="appleButton"
        onClick={onClick('apple')}
        color={theme.textDark}
      >
        {props.prefix} with Apple
      </Button>

      <HorizontalDivider mb={23} text="or" />
    </SignupOptionsContainer>
  );
};

export default SocialLogins;
