import { loginSchema } from '@propertypal/shared/src/constants/user';
import { setLoggedIn } from '@propertypal/shared/src/reducers/user';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import Button from '@propertypal/web-ui/src/buttons/Button';
import ErrorMessage from '@propertypal/web-ui/src/errors/ErrorMessage';
import TextInput from '@propertypal/web-ui/src/inputs/TextInput';
import { LinkText, Text } from '@propertypal/web-ui/src/typography';
import { useFormik } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoginComponentProps } from '../../types/LoginComponentProps';
import { LoginFormContainer } from './Login.style';

const initialValues = {
  email: '',
  pass: '',
};

const LoginForm = (props: LoginComponentProps) => {
  const router = useRouter();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const performLogin = async (values: any) => {
    setLoading(true);

    const result = await fetch('/api/auth/providers/propertypal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.email,
        password: values.pass,
      }),
    });

    if (result.ok) {
      trackGaEvent('login', {
        method: 'email',
        form_location: 'login',
      });

      dispatch(setLoggedIn(true));
      await router.push(props.redirectLocation);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: performLogin,
  });

  return (
    <LoginFormContainer visible>
      <form onSubmit={formik.handleSubmit}>
        <ErrorMessage
          testID="errorMessage"
          show={error}
          errorHeader="Oops"
          errorMessage="Sorry, couldn't log you in. Try again"
        />

        <TextInput
          containerStyle={{ marginTop: 10, marginBottom: 30 }}
          name="email"
          placeholder="Email address"
          type="email"
          onValueChange={formik.handleChange('email')}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
        />

        <TextInput
          containerStyle={{ marginBottom: 30 }}
          type="password"
          name="password"
          placeholder="Password"
          onValueChange={formik.handleChange('pass')}
          value={formik.values.pass}
          error={formik.touched.pass && formik.errors.pass}
        />

        <Text align="center" fontSize={14} mb={30}>
          <Link href="/forgot-password">
            <LinkText as="span">Forgot your password?</LinkText>
          </Link>
        </Text>

        <Button loading={loading} testID="loginSubmitButton" type="submit">
          Login
        </Button>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
